<script setup lang="ts">
import axios from 'axios';
import { onMounted, ref, type Ref } from 'vue';
import { baseUrl } from '@/stores/baseurl';

const version = ref(__APP_VERSION__)

const newVersionAvailable = ref(false)
const newVersion = ref("")

const changelog: Ref<Map<string, {description: string, features: Array<{icon: string, text: string}>, fixes: Array<{icon: string, text: string}>}>> = ref(new Map())
const currentVersionChangelog: Ref<{description: string, features: Array<{icon: string, text: string}>, fixes: Array<{icon: string, text: string}>} | undefined> = ref()

onMounted(async () => {
  // Check if version is aligned, otherwise reload and clear cache (using timestamp in URL)
  // Do it every hour !
  checkVersion()
  setInterval(() => {
    checkVersion()
  }, 60*60*1000)
})

async function checkVersion() {
  const versionResp = await axios.get(`${baseUrl}/version`)
  const buildVersion = versionResp.data.version
  if (buildVersion !== version.value) {
    newVersionAvailable.value = true
    newVersion.value = buildVersion

    // Load changelog to display what's new in this version
    const changelogResp = await axios.get(`${baseUrl}/changelog`)
    changelog.value = new Map(Object.entries(changelogResp.data))

    currentVersionChangelog.value = changelog.value.get(newVersion.value)
  }
}
</script>
<template>
  <v-dialog v-model="newVersionAvailable" persistent>
    <v-row justify="center">
      <v-card style="max-width: 500px">
        <v-card-title>
          <v-icon color="primary" class="mr-1">mdi-information</v-icon> Nouvelle version disponible
        </v-card-title>
        <v-card-item>
          Nouvelle version <b>{{ newVersion }}</b> disponible
        </v-card-item>
        <v-card-item style="max-height: 200px; overflow: auto;">
          <!-- <div class="text-center my-4">--- {{ currentVersionChangelog?.description }} ---</div> -->
          <!-- Features -->
          <div v-if="currentVersionChangelog?.features?.length && currentVersionChangelog?.features.length > 0">
            <v-divider class="mb-2"></v-divider>
            <v-card-subtitle>Nouvelles fonctionnalités</v-card-subtitle>
            <v-row v-for="(item, i) in currentVersionChangelog?.features" :key="i" :value="item" no-gutters justify="start">
              <div class="mr-1"><v-icon color="success">{{ item.icon }}</v-icon></div>
              <div style="width: calc(100% - 28px);">{{ item.text }}</div>
            </v-row>
          </div>
          <!-- Bug fixes -->
          <div v-if="currentVersionChangelog?.fixes.length && currentVersionChangelog?.fixes.length > 0">
            <v-divider class="mt-4"></v-divider>
            <v-card-subtitle>Correctifs</v-card-subtitle>
            <v-row v-for="(item, i) in currentVersionChangelog?.fixes" :key="i" :value="item" no-gutters>
              <div class="mr-1"><v-icon color="error">{{ item.icon }}</v-icon></div>
              <div style="width: calc(100% - 28px);">{{ item.text }}</div>
            </v-row>
          </div>
        </v-card-item>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('forceUpdate')">Mettre à jour</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-dialog>
</template>